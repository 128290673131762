/* StartScreen CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #start-screen {
    width: 100vw;
    height: 100vh;
    background-color: #004fc1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  #start-screen .content {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #start-screen .content h1 {
    font-size: 5vh;
    font-weight: 600;
    margin-bottom: 2vh;
    text-align: center;
    color: white;
  }

  #start-screen .logo-placeholder {
    width: 60vw;
    max-width: 400px;
    height: auto;
    margin-bottom: 3vh;
  }

  #start-screen .logo-placeholder img {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
  }

  #start-screen .content > div.text-white {
    font-size: 4vh;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    margin-bottom: 5vh;
    color: white;
  }

  #start-screen .reminder-text {
    position: absolute;
    bottom: 15vh;
    width: 80%;
    text-align: center;
    font-size: 2.5vh;
    font-weight: 400;
    line-height: 1.2;
    color: white;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 480px) {
    #start-screen .content h1 {
      font-size: 4.5vh;
    }
    #start-screen .content > div.text-white {
      font-size: 3.5vh;
    }
    #start-screen .reminder-text {
      font-size: 2.2vh;
      bottom: 4vh;
    }
    #start-screen .logo-placeholder {
      width: 70vw;
    }
  }

  @media screen and (max-width: 360px) {
    #start-screen .content h1 {
      font-size: 4vh;
    }
    #start-screen .content > div.text-white {
      font-size: 3vh;
    }
    #start-screen .reminder-text {
      font-size: 2vh;
      bottom: 3vh;
    }
    #start-screen .logo-placeholder {
      width: 80vw;
    }
  }
}

/* Option CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #member-login-screen {
    width: 100vw;
    height: 100vh;
    background-color: #e3f2ff;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  #member-login-screen .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #member-login-screen .header img {
    height: 6vh;
  }

  #member-login-screen .header .d-flex img {
    height: 5vh;
    cursor: pointer;
  }

  #member-login-screen > div:nth-child(2) {
    width: 100%;
    text-align: left;
    margin-top: 1vh;
  }

  #member-login-screen > div:nth-child(2) h2 {
    font-size: 3vh;
    font-weight: 800;
    color: #004fc1;
  }

  #member-login-screen .content {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #member-login-screen .content img {
    height: 8vh;
    margin-bottom: 2vh;
  }

  #member-login-screen .content h1 {
    font-size: 6vh;
    font-weight: 600;
    margin-bottom: 3vh;
    color: #004fc1;
  }

  #member-login-screen .content h4 {
    font-size: 3.5vh;
    font-weight: 700;
    margin-bottom: 4vh;
    text-align: center;
    color: #000;
  }

  #member-login-screen .content h3 {
    font-size: 3.5vh;
    font-weight: 700;
    margin-bottom: 2vh;
    color: #000;
  }

  #member-login-screen .content form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
  }

  #member-login-screen .content form .form-group {
    width: 100%;
    margin-bottom: 3vh;
  }

  #member-login-screen .content form .form-control {
    height: 6vh;
    font-size: 2.5vh;
    text-align: center;
    border-radius: 50px;
    border: 2px solid #004fc1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  #member-login-screen .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2vw;
  }

  #member-login-screen .buttons .position-relative {
    width: 35vw;
    max-width: 270px;
    height: auto;
    position: relative;
  }

  #member-login-screen .buttons button {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    height: auto;
  }

  #member-login-screen .buttons img {
    width: 100%;
    height: auto;
  }

  #member-login-screen .buttons span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5vh;
    font-weight: 600;
    color: inherit;
    pointer-events: none;
  }

  #member-login-screen .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    text-align: center;
    padding: 2vh;
  }

  #member-login-screen .overlay h2 {
    color: #000;
    font-size: 4vh;
    font-weight: 600;
    margin-bottom: 3vh;
    text-align: center;
  }

  #member-login-screen .overlay h1 {
    color: #000;
    font-size: 4vh;
    font-weight: 500;
    margin-bottom: 3vh;
  }

  #member-login-screen .overlay h4 {
    color: #000;
    font-size: 3vh;
    font-weight: 500;
    margin-bottom: 4vh;
    text-align: center;
  }

  #member-login-screen .overlay .d-flex {
    gap: 5vw;
  }

  #member-login-screen .overlay .position-relative {
    width: 35vw;
    max-width: 250px;
    height: auto;
    position: relative;
  }

  #member-login-screen .overlay img {
    width: 100%;
    height: auto;
  }

  #member-login-screen .overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5vh;
    font-weight: 600;
    color: inherit;
    pointer-events: none;
  }

  .spinner-border {
    width: 5vh;
    height: 5vh;
    border-width: 0.5vh;
  }

  @media screen and (max-width: 480px) {
    #member-login-screen .content h1 {
      font-size: 5vh;
    }
    #member-login-screen .content h4,
    #member-login-screen .content h3 {
      font-size: 3vh;
    }
    #member-login-screen .content form .form-control {
      height: 5vh;
      font-size: 2vh;
    }
    #member-login-screen .buttons span,
    #member-login-screen .overlay span {
      font-size: 2vh;
    }
    #member-login-screen .overlay h2,
    #member-login-screen .overlay h1,
    #member-login-screen .overlay h4 {
      font-size: 3vh;
    }
  }

  @media screen and (max-width: 360px) {
    #member-login-screen .content h1 {
      font-size: 4.5vh;
    }
    #member-login-screen .content h4,
    #member-login-screen .content h3 {
      font-size: 2.5vh;
    }
    #member-login-screen .content form .form-control {
      height: 4.5vh;
      font-size: 1.8vh;
    }
    #member-login-screen .buttons span,
    #member-login-screen .overlay span {
      font-size: 1.8vh;
    }
    #member-login-screen .overlay h2,
    #member-login-screen .overlay h1,
    #member-login-screen .overlay h4 {
      font-size: 2.5vh;
    }
  }
}

/* SelectService CSS */

.service-box.washer {
  background-color: #004fc1;
  border-color: #004fc1;
}

.service-box.dryer {
  background-color: #f76b8a;
  border-color: #f76b8a;
}

.service-box.vending {
  background-color: #222831;
  border-color: #222831;
}

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #select-service-screen {
    width: 100vw;
    height: 100vh;
    background-color: #e3f2ff;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  #select-service-screen .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #select-service-screen .header img {
    height: 6vh;
  }

  #select-service-screen .header .d-flex img {
    height: 5vh;
    cursor: pointer;
  }

  #select-service-screen > div:nth-child(2) {
    width: 100%;
    text-align: left;
    margin-top: 1vh;
  }

  #select-service-screen > div:nth-child(2) h2 {
    font-size: 3vh;
    font-weight: 800;
    color: #004fc1;
  }

  #select-service-screen .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #select-service-screen .content h3 {
    font-size: 5vh;
    font-weight: 750;
    margin-bottom: 5vh;
    color: #000;
    text-align: center;
  }

  #select-service-screen .content > div {
    width: 80vw !important;
    max-width: 700px;
    height: 12vh !important;
    max-height: 200px;
    font-size: 4vh !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    border-radius: 4vh !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 5vw !important;
    margin-bottom: 3vh !important;
    cursor: pointer !important;
  }

  #select-service-screen .content > div h6 {
    margin-bottom: 0 !important;
    font-size: 5vh !important;
  }

  #select-service-screen .content > div img {
    height: 5vh !important;
  }

  @media screen and (max-width: 480px) {
    #select-service-screen .content h3 {
      font-size: 4.5vh;
      margin-bottom: 4vh;
    }
    #select-service-screen .content > div {
      height: 10vh !important;
    }
    #select-service-screen .content > div h6 {
      font-size: 4.5vh !important;
    }
    #select-service-screen .content > div img {
      height: 4.5vh !important;
    }
  }

  @media screen and (max-width: 360px) {
    #select-service-screen .content h3 {
      font-size: 4vh;
      margin-bottom: 3vh;
    }
    #select-service-screen .content > div {
      height: 9vh !important;
    }
    #select-service-screen .content > div h6 {
      font-size: 4vh !important;
    }
    #select-service-screen .content > div img {
      height: 4vh !important;
    }
  }
}

/* Machines CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
    #machines-screen {
        width: 100vw;
        height: 100vh;
        padding: 2vh;
    }

    #machines-screen .header img {
        height: 6vh;
    }

    #machines-screen .header .d-flex img {
        height: 5vh;
    }

    #machines-screen h2 {
        font-size: 3vh;
        margin: 2vh 0;
    }

    #machines-screen .service-button {
        width: 50vw;
        max-width: 400px;
        height: 10vh;
        max-height: 150px;
        font-size: 4vh;
        margin: 2vh 0 3vh 0;
    }

    #machines-screen .service-button img {
        height: 5vh;
        margin-left: 2vw;
    }

    #machines-screen .machines-container {
        height: 65vh;
        width: 90vw;
        margin-right: -2vw;
    }

    #machines-screen .machines-grid {
        gap: 2vh;
        padding: 1vh;
    }

    #machines-screen .machine-card {
        width: 40vw;
        max-width: 420px;
        height: auto;
        min-height: 25vh;
        padding: 2vh;
        border-radius: 2vh;
    }

    #machines-screen .machine-card .machine-image-container {
        width: 90%;
        height: auto;
        aspect-ratio: 1;
        margin-bottom: 1vh;
        padding: 1vh;
    }

    #machines-screen .machine-card .machine-number {
        font-size: 4vh;
    }

    #machines-screen .machine-card .machine-info {
        font-size: 2.5vh;
        padding: 1vh;
        margin-top: -0.5vh;
    }

    #machines-screen .machine-card .status-indicator {
        font-size: 2vh;
        padding: 1vh 2vh;
        margin-top: 1vh;
    }
}

@media screen and (max-width: 480px) {
    #machines-screen .machine-card {
        width: 80vw;
        min-height: 20vh;
    }

    #machines-screen .service-button {
        width: 70vw;
        height: 8vh;
        font-size: 3.5vh;
    }

    #machines-screen .machine-card .machine-info {
        font-size: 2.2vh;
    }

    #machines-screen .machine-card .status-indicator {
        font-size: 1.8vh;
        padding: 0.8vh 1.5vh;
    }
}

@media screen and (max-width: 360px) {
    #machines-screen .machine-card {
        width: 85vw;
        min-height: 18vh;
    }

    #machines-screen .service-button {
        width: 75vw;
        height: 7vh;
        font-size: 3vh;
    }

    #machines-screen .machine-card .machine-info {
        font-size: 2vh;
    }

    #machines-screen .machine-card .status-indicator {
        font-size: 1.6vh;
        padding: 0.6vh 1.2vh;
    }
}

#machines-screen .spinner-container {
    margin-top: 10vh;
    text-align: center;
}

#machines-screen .spinner-border {
    color: #004FC1;
    width: 10vh;
    height: 10vh;
}

@media screen and (max-width: 480px) {
    #machines-screen .spinner-border {
        width: 8vh;
        height: 8vh;
    }
}

#machines-screen .scroll-button {
    height: 5vh;
    cursor: pointer;
    position: absolute;
    right: 2vw;
}

#machines-screen .scroll-up {
    top: 12vh;
}

#machines-screen .scroll-down {
    bottom: 5vh;
}

/* Payment CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #credit-card-screen,
  #payment-selection-screen {
    width: 100vw;
    height: 100vh;
    padding: 2vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #credit-card-screen {
    background-color: #f5f5f5;
  }

  #payment-selection-screen {
    background-color: #e3f2ff;
  }

  #credit-card-screen .header,
  #payment-selection-screen .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #credit-card-screen .header img,
  #payment-selection-screen .header img {
    height: 6vh;
  }

  #credit-card-screen .header .d-flex img,
  #payment-selection-screen .header .d-flex img {
    height: 5vh;
    cursor: pointer;
  }

  #credit-card-screen > div:nth-child(2),
  #payment-selection-screen > div:nth-child(2) {
    width: 100%;
    text-align: left;
    margin-top: 1vh;
  }

  #credit-card-screen > div:nth-child(2) h2,
  #payment-selection-screen > div:nth-child(2) h2 {
    font-size: 3vh;
    font-weight: 800;
    color: #004fc1;
  }

  #credit-card-screen h2 {
    color: #000;
    font-size: 4vh;
    font-weight: 600;
    margin: 5vh 0;
    text-align: center;
  }

  #payment-selection-screen h2 {
    color: #000;
    font-size: 4.5vh;
    font-weight: 700;
    margin: 5vh 0;
    text-align: center;
  }

  #credit-card-screen img[alt="Tap Card"] {
    width: 70%;
    max-width: 500px;
    height: auto;
    margin-bottom: 4vh;
  }

  #payment-selection-screen .payment-option {
    width: 80vw;
    max-width: 800px;
    height: 30vh;
    max-height: 350px;
    margin-top: 5vh;
    background-color: #fff;
    border-radius: 2vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #payment-selection-screen .payment-option img {
    width: 70%;
    max-width: 600px;
    height: auto;
    margin-bottom: 2vh;
  }

  #payment-selection-screen .payment-option p {
    font-size: 3vh;
    font-weight: 600;
    color: #000;
    margin: 0;
    text-align: center;
  }

  #credit-card-screen .btn-danger,
  #payment-selection-screen .btn-danger {
    width: 40vw;
    max-width: 400px;
    height: 8vh;
    font-size: 3vh;
    font-weight: 600;
    border-radius: 5vh;
    margin-top: 2vh;
  }

  #payment-selection-screen .btn-danger {
    height: 10vh;
    margin-top: 8vh;
  }

  .spinner-border {
    color: #004fc1;
    width: 5vh;
    height: 5vh;
  }

  @media screen and (max-width: 480px) {
    #credit-card-screen .header img,
    #payment-selection-screen .header img {
      height: 5vh;
    }

    #credit-card-screen .header .d-flex img,
    #payment-selection-screen .header .d-flex img {
      height: 4vh;
    }

    #credit-card-screen > div:nth-child(2) h2,
    #payment-selection-screen > div:nth-child(2) h2 {
      font-size: 2.5vh;
    }

    #credit-card-screen h2,
    #payment-selection-screen h2 {
      font-size: 3.5vh;
    }

    #credit-card-screen img[alt="Tap Card"] {
      width: 80%;
    }

    #payment-selection-screen .payment-option {
      width: 90vw;
      height: auto;
      margin-top: 4vh;
    }

    #payment-selection-screen .payment-option img {
      width: 80%;
    }

    #payment-selection-screen .payment-option p {
      font-size: 2.5vh;
    }

    #credit-card-screen .btn-danger,
    #payment-selection-screen .btn-danger {
      width: 60vw;
      max-width: 300px;
      height: 8vh;
      font-size: 2.5vh;
      margin-top: 4vh;
    }

    #payment-selection-screen .btn-danger {
      height: 8vh;
      margin-top: 6vh;
    }

    .spinner-border {
      width: 4vh;
      height: 4vh;
    }
  }

  @media screen and (max-width: 360px) {
    #credit-card-screen .header img,
    #payment-selection-screen .header img {
      height: 4.5vh;
    }

    #credit-card-screen .header .d-flex img,
    #payment-selection-screen .header .d-flex img {
      height: 3.5vh;
    }

    #credit-card-screen > div:nth-child(2) h2,
    #payment-selection-screen > div:nth-child(2) h2 {
      font-size: 2vh;
    }

    #credit-card-screen h2,
    #payment-selection-screen h2 {
      font-size: 3vh;
    }

    #credit-card-screen img[alt="Tap Card"] {
      width: 85%;
    }

    #payment-selection-screen .payment-option {
      width: 95vw;
      margin-top: 3vh;
    }

    #payment-selection-screen .payment-option img {
      width: 85%;
    }

    #payment-selection-screen .payment-option p {
      font-size: 2vh;
    }

    #credit-card-screen .btn-danger,
    #payment-selection-screen .btn-danger {
      width: 65vw;
      max-width: 260px;
      height: 7vh;
      font-size: 2vh;
      margin-top: 3vh;
    }

    #payment-selection-screen .btn-danger {
      height: 7vh;
      margin-top: 5vh;
    }

    .spinner-border {
      width: 3.5vh;
      height: 3.5vh;
    }
  }
}

/* Faq CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #faq-page {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  #faq-page .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #faq-page .header img {
    height: 6vh;
  }

  #faq-page .header .d-flex img {
    height: 5vh;
    cursor: pointer;
  }

  #faq-page > div:nth-child(2) {
    width: 100%;
    text-align: left;
    margin-top: 1vh;
  }

  #faq-page > div:nth-child(2) h2 {
    font-size: 3vh;
    font-weight: 800;
    color: #004fc1;
  }

  #faq-page .content {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #faq-page .content img {
    height: 10vh;
    margin-bottom: 2vh;
  }

  #faq-page .content h1 {
    font-size: 6vh;
    font-weight: 600;
    margin-top: 2vh;
    color: #004fc1;
  }

  #faq-page .content h4 {
    font-size: 3vh;
    font-weight: 500;
    margin-top: 2vh;
    color: #000;
  }

  @media screen and (max-width: 480px) {
    #faq-page .header img {
      height: 5vh;
    }

    #faq-page .header .d-flex img {
      height: 4vh;
    }

    #faq-page > div:nth-child(2) h2 {
      font-size: 2.5vh;
    }

    #faq-page .content img {
      height: 9vh;
    }

    #faq-page .content h1 {
      font-size: 5vh;
    }

    #faq-page .content h4 {
      font-size: 2.5vh;
    }
  }

  @media screen and (max-width: 360px) {
    #faq-page .header img {
      height: 4.5vh;
    }

    #faq-page .header .d-flex img {
      height: 3.5vh;
    }

    #faq-page > div:nth-child(2) h2 {
      font-size: 2vh;
    }

    #faq-page .content img {
      height: 8vh;
    }

    #faq-page .content h1 {
      font-size: 4.5vh;
    }

    #faq-page .content h4 {
      font-size: 2vh;
    }
  }
}

/* ThankYou CSS */

@media screen and (max-width: 1079px), screen and (max-height: 1919px) {
  #thank-you-screen {
    width: 100vw;
    height: 100vh;
    background-color: #e3f2ff;
    padding: 2vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #thank-you-screen .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #thank-you-screen .header img {
    height: 6vh;
  }

  #thank-you-screen .header .d-flex img {
    height: 5vh;
    cursor: pointer;
  }

  #thank-you-screen > div:nth-child(2) {
    width: 100%;
    text-align: left;
    margin-top: 1vh;
  }

  #thank-you-screen > div:nth-child(2) h2 {
    font-size: 3vh;
    font-weight: 800;
    color: #004fc1;
  }

  #thank-you-screen .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5vh;
  }

  #thank-you-screen .content img {
    height: 10vh;
    margin-bottom: 2vh;
  }

  #thank-you-screen .content h2 {
    color: #004fc1;
    font-size: 5vh;
    font-weight: 600;
    margin-bottom: 2vh;
  }

  #thank-you-screen .content p {
    color: #000;
    font-size: 3vh;
    font-weight: 600;
    line-height: 1.4;
    margin: 2vh 0;
  }

  @media screen and (max-width: 480px) {
    #thank-you-screen .header img {
      height: 5vh;
    }

    #thank-you-screen .header .d-flex img {
      height: 4vh;
    }

    #thank-you-screen > div:nth-child(2) h2 {
      font-size: 2.5vh;
    }

    #thank-you-screen .content img {
      height: 9vh;
    }

    #thank-you-screen .content h2 {
      font-size: 4.5vh;
    }

    #thank-you-screen .content p {
      font-size: 2.5vh;
    }
  }

  @media screen and (max-width: 360px) {
    #thank-you-screen .header img {
      height: 4.5vh;
    }

    #thank-you-screen .header .d-flex img {
      height: 3.5vh;
    }

    #thank-you-screen > div:nth-child(2) h2 {
      font-size: 2vh;
    }

    #thank-you-screen .content img {
      height: 8vh;
    }

    #thank-you-screen .content h2 {
      font-size: 4vh;
    }

    #thank-you-screen .content p {
      font-size: 2vh;
    }
  }
}
